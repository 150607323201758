<template>
  <a
    :href="href"
    :class="{ 'has-cursor-wait': processing, hoverable: hoverable }"
    class="attachment has-cursor-pointer"
    target="_blank"
  >
    <div class="image-wrapper">
      <loading v-if="loadingSelf || loading" :size="2.25" />
      <img v-else :src="thumbnail" />
    </div>
    <div class="attachment-info">
      <p class="has-overflow-ellipsis-right">
        <strong
          ><u>{{ fileName }}</u></strong
        ><br />
        <small class="has-text-grey">{{ humanFileSize }}</small>
      </p>
    </div>
    <b-icon
      v-if="deleteable"
      icon="trash-alt"
      size="is-small"
      type="is-danger"
      @click.native.prevent.stop="$emit('onDelete')"
    />
  </a>
</template>

<script>
import { getIcon, MIME_ICON } from "@src/services/mimeType";
import { isImage, humanFileSize } from "@src/services/fileutil";
import { getDownloadURL } from "firebase/storage";

export default {
  name: "TaskAttachment",
  props: {
    author: {
      required: false,
      type: Boolean,
      default: false
    },
    hoverable: {
      required: false,
      type: Boolean,
      default: true
    },
    fileType: {
      required: true,
      type: String
    },
    fileName: {
      required: true,
      type: String
    },
    fileSize: {
      required: false,
      type: Number,
      default: null
    },
    fileRef: {
      required: true,
      type: Object
    },
    thumbnailRef: {
      required: false,
      type: Object,
      default: null
    },
    loading: {
      required: false,
      type: Boolean,
      default: false
    },
    showRelativeTime: {
      required: false,
      type: Boolean,
      default: true
    },
    deleteable: {
      required: false,
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      uploadProgress: 0,
      loadingSelf: false,
      href: null,
      processing: false
    };
  },
  computed: {
    humanFileSize() {
      return this.fileSize || this.fileSize === 0
        ? humanFileSize(this.fileSize)
        : "";
    },
    isImage() {
      return isImage(this.fileType);
    },
    thumbnail() {
      if (this.$_.startsWith(this.fileType, "image") && this.thumbnailRef) {
        const thumbnailUrl = this.$store.getters.cached({
          key: this.thumbnailRef.fullPath
        });
        return thumbnailUrl ? thumbnailUrl : MIME_ICON["default"];
      }
      return getIcon(this.fileType);
    }
  },
  watch: {
    "thumbnailRef.fullPath"() {
      this.getThumbnailUrl();
    },
    loading(newVal, oldVal) {
      if (oldVal && !newVal) {
        this.getDownloadURL();
      }
    }
  },
  created() {
    this.thumbnailRef && this.getThumbnailUrl();
    !this.loading && this.fileRef && this.getDownloadURL();
  },
  methods: {
    getThumbnailUrl() {
      if (this.$_.isEmpty(this.thumbnailRef)) return;
      this.loadingSelf = true;
      getDownloadURL(this.thumbnailRef)
        .then(url => {
          this.$store.commit("cache", {
            key: this.thumbnailRef.fullPath,
            value: url
          });
        })
        .finally(() => (this.loadingSelf = false));
    },
    getDownloadURL() {
      this.processing = true;
      getDownloadURL(this.fileRef)
        .then(url => {
          this.href = url;
          this.processing = false;
        })
        .catch(() => {
          this.processing = false;
          this.$toast.open({
            message: `Error getting download url!`,
            position: "is-bottom",
            type: "is-danger"
          });
        });
    }
  }
};
</script>

<style lang="scss" scoped>
@import "~@sass/bulma/custom-variables";
.attachment {
  display: grid;
  grid-template-columns: 3em 1fr;
  grid-column-gap: 1em;
  text-decoration: none;
  .attachment-info {
    overflow: hidden;
  }
  .image-wrapper {
    height: 3em;
    width: 3em;
    .custom-loader {
      position: relative;
      top: -1em;
      left: -1em;
    }
    img {
      display: block;
      border-radius: 0.3em;
      max-height: 3em;
      box-shadow: 0 0 0.25rem 0 rgba($black, 0.15);
    }
  }
  & > span.icon {
    grid-column: 3;
    align-self: center;
  }
}
</style>
